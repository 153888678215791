import React from "react";
import Logo from "../images/DRx-Logo-Header.png";
export default function Header() {
  return (
    <nav className="bg-white p-4">
      <div class="flex items-center">
        <div class="w-full px-4 flex justify-start">
          <div class="relative">
            <img width={130} height={130} src={Logo} />
          </div>
        </div>
      </div>
    </nav>
  );
}

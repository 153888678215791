import React from "react";

export default function NotSupposed() {
  return (
    <div class="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
      <h1 class="text-center align-middle font-open-sans">
        You are not supposed to be here, follow the steps through Rex on
        Discord.
      </h1>
    </div>
  );
}

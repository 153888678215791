import React from "react";
import Verification from "../components/Verification";
import { useLocation } from "react-router-dom";
import * as Client from "../utils/client";
import NotSupposed from "../components/NotSupposed";
import Web3 from "web3";

import neva from "../images/Neva.png";
import rex from "../images/rex.png";

import styles from "../styles/mainVerification.module.css";

let web3 = new Web3();
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function MainVerification(props) {
  const query = useQuery();

  if (
    !query.get("id") ||
    !web3.utils.isAddress(web3.utils.toChecksumAddress(query.get("wallet")))
  ) {
    return <NotSupposed />;
  }

  return (
    <div className={styles.page}>
      <div className="min-h-screen py-3 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
          <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20 ">
            <div className="flex text-sm justify-between mb-4 font-open-sans">
              <div>
                Desired Wallet:{" "}
                {query.get("wallet")
                  ? Client.formatAddress(query.get("wallet"))
                  : "?"}
              </div>
            </div>
            <div className="max-w-md mx-auto">
              <div className="divide-y divide-gray-200">
                <div className="pb-8 text-base leading-6 space-y-4 sm:text-lg sm:leading-7 font-open-sans">
                  <p>
                    In order to give you the appropriate roles on Discord, we
                    will need to verify that it's actually you who owns those
                    Drug Receipts.
                  </p>
                  <p>
                    Connect your wallet now, and sign our message to prove it's
                    you!
                  </p>
                </div>

                <Verification />

                <div className="pt-6 text-base leading-6 font-open-sans sm:text-lg sm:leading-7">
                  <p>Want to dig deeper into Drug Receipts?</p>
                  <p>
                    <a
                      href="https://drugreceipts.com/"
                      className="text-dr_gray hover:opacity-70"
                    >
                      View our website &rarr;{" "}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.neva}>
        <img className={styles.nevaImg} src={rex} alt="Rex" />
      </div>
    </div>
  );
}

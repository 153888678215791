import React from "react";

import styles from "../styles/buttonComponent.module.css";

const ButtonComponent = ({ error, text, callBack }) => {
  return (
    <button disabled={error} className={styles.button} onClick={callBack}>
      <p className="font-pressio-condensed">{text}</p>
    </button>
  );
};

export default ButtonComponent;

import React, { useEffect, useState } from "react";
import { useWallet } from "use-wallet";
import { useParams, useLocation } from "react-router-dom";
import * as Client from "../utils/client";

import ButtonComponent from "../components/ButtonComponent";

import styles from "../styles/verification.module.css";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Verification() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const wallet = useWallet();
  const params = useParams();
  const query = useQuery();

  console.log(new URLSearchParams(useLocation().search));
  console.log(new URLSearchParams(useLocation().search).getAll("id"));

  const doAuth = async () => {
    try {
      await Client.signMessage(
        query.get("id"),
        query.get("wallet"),
        wallet
      ).then((sig) => {
        if (sig.status === "OK") {
          setSuccess(
            "You have signed successfully, and everything is appearing right. You can now close this window and you will retrieve your Discord role shortly."
          );
        }
      });
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
  };

  const setDisabled = () => {
    if (query.get("wallet").toLowerCase() !== wallet.account.toLowerCase()) {
      setError(
        "The wallet that you have connected is not the wallet you are trying to authenticate."
      );
      return true;
    }
    setError("");
    return false;
  };

  useEffect(() => {
    if (wallet.account && params) {
      setDisabled();
    }
  }, [wallet.account]);

  useEffect(() => {
    Client.updateProvider(wallet.ethereum);
  }, [wallet]);

  return (
    <div className="py-6">
      {wallet.status === "connected" ? (
        <div className={`${styles.buttonsBox} space-y-4`}>
          <div className={`text-sm font-open-sans`}>
            Connected:{" "}
            {wallet.account ? Client.formatAddress(wallet.account) : "?"}
          </div>
          {error && <div className="text-xs text-red-600">{error}</div>}
          {success && <div className="text-xs text-green-600">{success}</div>}

          <ButtonComponent
            error={error}
            text="Authenticate"
            callBack={() => doAuth()}
          />

          <ButtonComponent
            error={error}
            text="Disconnect Wallet"
            callBack={() => wallet.reset()}
          />
        </div>
      ) : (
        <div>
          <div
            className="
            text-base leading-6 space-y-4 text-gray-700 text-center mb-4 font-open-sans"
          >
            <p>Select Wallet</p>
          </div>
          <div className={`${styles.buttonsBox} space-y-4`}>
            <ButtonComponent
              error={error}
              text="MetaMask"
              callBack={() => wallet.connect()}
            />

            <ButtonComponent
              error={error}
              text="WalletConnect"
              callBack={() => wallet.connect("walletconnect")}
            />

            <ButtonComponent
              error={error}
              text="Coinbase Wallet"
              callBack={() => wallet.connect("walletlink")}
            />
          </div>
        </div>
      )}
    </div>
  );
}

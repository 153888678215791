import React from "react";
import MainVerification from "./views/MainVerification";
import Header from "./components/Header";
import "./index.css";
import { UseWalletProvider } from "use-wallet";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import NotSupposed from "./components/NotSupposed";

function App() {
  return (
    <UseWalletProvider
      connectors={{
        injected: {
          chainId: 1,
        },
        walletconnect: {
          chainId: 1,
          rpcUrl:
            "https://mainnet.infura.io/v3/4d62989aa1ba4ee29f4e277d91b344d7",
        },
        walletlink: {
          chainId: 1,
          url:
            "https://mainnet.infura.io/v3/4d62989aa1ba4ee29f4e277d91b344d7",
        },
      }}
    >
      <Router>
        <Header />
        <Switch>
          <Route exact path="/">
            <NotSupposed />
          </Route>
          <Route path="/verify">
            <MainVerification />
          </Route>
        </Switch>
      </Router>
    </UseWalletProvider>
  );
}

export default App;

import Web3 from "web3";
import { ethers } from "ethers";
let provider, signer;

const BASE_URL = window.location.href.includes("localhost")
  ? "http://localhost:3003"
  : "https://neva-api.drugreceipts.com";
  // : "https://api.neva.srv.fluf.app";
  
const getHeaders = () => {
  return {
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
};
export const updateProvider = (myProvider) => {
  if (!myProvider) {
    provider = undefined;
    signer = undefined;
    return;
  }
  if (!provider) {
    provider = new ethers.providers.Web3Provider(myProvider, "homestead");
    signer = provider.getSigner();
  }
};

export const formatAddress = (address) => {
  return (
    address.substr(0, 5) +
    " ... " +
    address.substr(address.length - 5, address.length)
  );
};

export const signMessage = async (discordid, walletToAuthenticate, wallet) => {
  let message = `Drug Receipts: This signature is totally free of charge. If you have any questions, please feel free to contact us on Discord. The address we are trying to verify is: ${walletToAuthenticate.toLowerCase()}`;

  let signature;

  discordid = atob(discordid);

  if (
    wallet.connector === "walletconnect" ||
    wallet.connector === "walletlink"
  ) {
    signature = await provider.send("personal_sign", [
      ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message)),
      walletToAuthenticate.toLowerCase(),
    ]);
  } else {
    signature = await signer.signMessage(message);
  }

  let status = await postSignature(
    discordid,
    walletToAuthenticate.toLowerCase(),
    signature
  );

  return status;
};

const extractBody = (res) => {
  if (!res.ok) return res.text();
  if (res.ok) return res.json();
};

const postSignature = async (discordid, walletaddress, signature) => {
  return await fetch(`${BASE_URL}/verify`, {
    method: "POST",
    ...getHeaders(),
    body: JSON.stringify({ discordid, walletaddress, signature }),
  }).then((response) => extractBody(response));
};
